<template>
  <div>
    <v-container>
      <v-row>
        <router-link :to="{ name: 'Finance' }">Zurück zum Finanz-Menü</router-link>
        <v-spacer></v-spacer>
        <v-select
          outlined
          style="max-width: 300px"
          v-model="filterMonth"
          @change="filterCards()"
          :items="filter.time"
          label="Zeitraum"
          dense
        ></v-select>
      </v-row>
    </v-container>
    <div>
      <v-container>
        <v-row>
          <v-col cols="12" md="3" v-for="(el, key, i) in data" :key="i">
            <unvergessen-category-card :element="el" :type="key" :month="month"></unvergessen-category-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import CategoryCard from '@/components/finance/controlling/CategoryCard.vue'
import axios from 'axios'

export default {
  data () {
    return {
      data: {},
      filter: {
        time: null
      },
      filterMonth: 'Aktueller Monat',
      month: 0
    }
  },
  methods: {
    parseDate (input) {
      return input.replace('Januar', 'January')
        .replace('Februar', 'February')
        .replace('März', 'March')
        .replace('Mai', 'May')
        .replace('Juni', 'June')
        .replace('Juli', 'July')
        .replace('Oktober', 'October')
        .replace('Dezember', 'December')
    },
    filterCards () {
      var data = {}

      // Month
      var d = null
      if (this.filterMonth === 'Aktueller Monat') {
        axios.post('/controlling/revenueOverview', data)
          .then(res => {
            this.data = res.data
            delete this.data.flower
          })
          .catch(() => {})
        return
      } else {
        d = new Date(this.parseDate(this.filterMonth))
      }
      this.month = d.getMonth()

      data.period = {
        start: new Date(d.getFullYear(), d.getMonth(), 1, 2),
        end: new Date(d.getFullYear(), d.getMonth() + 1, 0, 2)
      }

      // Request
      axios.post('/controlling/revenueOverview', data)
        .then(res => {
          this.data = res.data
          delete this.data.flower
        })
        .catch(() => {})
    }
  },
  created () {
    axios.post('/controlling/revenueOverview', {})
      .then(res => {
        this.data = res.data
        delete this.data.flower
      })
    // Calculate possible dates to filter (till Oktober 2021)
    var d = new Date()
    var goon = true
    var dArr = ['Aktueller Monat']
    var i = 1

    while (goon) {
      dArr.push(new Date(new Date(new Date().setDate(28)).setMonth(d.getMonth() - i)).toLocaleDateString('de-DE', { year: 'numeric', month: 'long' }))
      if (dArr[i] === 'Oktober 2021') {
        goon = false
      }
      i++
    }
    this.filter.time = dArr
  },
  components: {
    unvergessenCategoryCard: CategoryCard
  }
}
</script>
