<template>
  <v-card class="unvergessen-shadow" style="cursor: pointer" v-ripple @click="$router.push({ name: 'ControllingDetails', params: { id: type }, query: { m: month !== 0 ? month : undefined } })">
    <div class="grey3">
      <h3 style="padding: 10px; text-align: center">
        {{ heading }}
      </h3>
    </div>
    <div style="padding: 10px">
      <v-container>
        <v-row>
          <!-- Ist Umsatz bis heute -->
          <v-col cols="6">
            <div class="title" :class="{ 'success--text': element.is > element.aimToday }">
              {{ (element.is/100).toFixed(2) }}€
            </div>
            <div class="caption">
              Ist-Umsatz {{ new Date(element.date).toLocaleDateString('de-De', this.dateOptions) }}
            </div>
          </v-col>

          <!-- Plan Umsatz bis heute -->
          <v-col cols="6">
            <div class="title">
              {{ (element.aimToday/100).toFixed(2) }}€
            </div>
            <div class="caption">
              Plan-Umsatz {{ new Date(element.date).toLocaleDateString('de-De', this.dateOptions) }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <!-- Abweichung -->
          <v-col cols="6">
            <div class="title" :class="{ 'success--text': element.deviation > 0 }">
              <span v-if="element.deviation > 0">+</span>{{ (element.deviation) }}%
            </div>
            <div class="caption">
              Abweichung Ist-Plan
            </div>
          </v-col>

          <!-- Plan Umsatz bis heute -->
          <v-col cols="6">
            <div class="title">
              {{ (element.aim/100).toFixed(2) }}€
            </div>
            <div class="caption">
              Plan-Umsatz {{ new Date(element.date).toLocaleString('de-De', { month: 'long' }) }}
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- Progress -->
      <v-container>
        <v-progress-linear
          class="animated"
          :color="computedColor"
          height="20"
          :value="(element.is / element.aim)*100"
          striped
          :buffer-value="(element.aimToday / element.aim)*100"
          style="border-radius: 10px; border-width: 1px; border-style: solid; border-color: lightgrey"
        ></v-progress-linear>
      </v-container>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['element', 'type', 'month'],
  data () {
    return {
      dateOptions: { day: '2-digit', month: '2-digit', year: 'numeric' }
    }
  },
  computed: {
    heading () {
      switch (this.type) {
        case 'candle':
          return 'Kerzen'
        case 'flower':
          return 'Blumen'
        case 'memorialMail':
          return 'Gedenk-Post'
        case 'memorialPage':
          return 'Gedenkseiten'
        case 'memorialProducts':
          return 'Trauerprodukte'
        case 'marketing':
          return 'Vermarktung'
        case 'global':
          return 'Gesamt-Umsatz'
        case 'core':
          return 'Kernprodukte (GP, GS, K)'
        case 'agency':
          return 'Agentur'
        default:
          return 'Unbekannt'
      }
    },
    computedColor () {
      var color = ''
      var devi = this.element.deviation
      if (devi < -30) {
        color = 'error'
      } else if (devi >= -30 && devi < -10) {
        color = 'yellow darken-2'
      } else if (devi >= -10 && devi < 0) {
        return 'success lighten-1'
      } else if (devi >= 0 && devi < 10) {
        return 'success darken-1'
      } else if (devi >= 10 && devi < 30) {
        return 'success darken-2'
      } else {
        return 'success darken-4'
      }
      return color
    }
  }
}
</script>

<style scoped>
* >>> .v-progress-linear--striped .v-progress-linear__determinate {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0;
  }

  100% {
    background-position: 0 0;
  }
}
</style>
